import { array } from "yup";
import { useModalStore } from "./modal";

interface User {
    name: string;
    surname: string;
    email: string;
    phone: string;
    cart_id: string | null;
}
interface State {
    token: string | null;
    data: User | null;
    loggedIn: boolean | null;
    viewedProducts: Array<number | undefined>;
}

export const useUserStore = defineStore("user", {
    state: (): State => ({
        token: null,
        data: null,
        loggedIn: null,
        viewedProducts: [],
    }),
    actions: {
        async login(credentials: object) {
            const res = await useFetchWithCredentials<{ token: string }>(
                "/api/v1/user/login",
                {
                    method: "post",
                    body: credentials,
                },
            );

            if (res.status.value === "success") {
                this.token = res.data.value?.token ?? null;

                await useFetchWithCredentials("api/v1/user/products/viewed", {
                    method: "post",
                    body: this.viewedProducts.slice(-10),
                });
                this.viewedProducts = [];

                await this.fetch();
            }

            return res;
        },
        async fetch() {
            const res = await useFetchWithCredentials<User>("/api/v1/user", {
                key: "user",
            });

            if (res.status.value === "success") {
                this.data = res.data.value;
                this.loggedIn = true;
            }
        },
        logout() {
            useCookie("XSRF-TOKEN").value = null;
            this.$reset();
            navigateTo("/");
            useModalStore().close();
        },
    },
    persist: {
        paths: ["token", "viewedProducts"],
    },
});
