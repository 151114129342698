import { useToast } from "vue-toastification";

export const useServerValidation = (res: any, form = null) => {
    if (res.status.value === "error") {
        if ("errors" in res?.error?.value?.data) {
            for (const [key, value] of Object.entries(
                res.error.value.data.errors,
            )) {
                form.setFieldError(key, (value as any).join("\n"));
            }
        } else {
            useToast().error(res.error.value.data.message);
        }
    }

    return res;
};
