export const useModalStore = defineStore("modal", {
    state: () => ({
        isOpen: false,
        view: {},
        args: {},
        className: null
    }),
    actions: {
        open(component: object, args = {}) {
            this.isOpen = true;
            this.view = markRaw(component);
            this.className=args.parentClass
            delete args.parentClass
            this.args = args;
        },
        close() {
            this.isOpen = false;
            this.view = {};
        },
    },
});
