import { useToast } from "vue-toastification";
import type { CartState } from "~/types";

type Properties = Pick<CartState, "cart" | "cartId">;
type Methods = Pick<
    CartState,
    | "load"
    | "addProduct"
    | "removeProduct"
    | "applyPromocode"
    | "removePromocode"
    | "butSolution"
> & {
    hasProduct(id: number): boolean;
};

export const useCartStore = defineStore<"cart", Properties, {}, Methods>(
    "cart",
    {
        state: () => ({
            cart: null,
            cartId: null,
        }),
        actions: {
            async load() {
                const auth = useAuth();
                this.cartId ??= auth.data?.cart_id ?? null;
                if (!this.cartId) {
                    return;
                }

                const res = await useFetchWithCredentials(
                    `/api/v1/cart/${this.cartId}`,
                    {
                        key: "stores.cart.load",
                    },
                );
                if (res.error.value?.statusCode === 404) {
                    this.cartId = null;
                    if (auth.data) {
                        auth.data.cart_id = null;
                    }
                    return;
                }
                if (res.status.value !== "success") {
                    return;
                }
                this.cart = res.data.value as CartState["cart"];
            },
            async addProduct(productId, quantity, showToast = true) {
                const body = {
                    cart_id: this.cartId ?? undefined,
                    product_id: productId,
                    quantity,
                };
                if (!quantity) {
                    delete body.quantity;
                }
                const res = await useFetchWithCredentials<{ cart_id: string }>(
                    "/api/v1/cart/add",
                    {
                        method: "POST",
                        body
                    },
                );
                const hasProductBefore = this.hasProduct(productId);
                if (res.status.value === "success") {
                    this.cartId = res.data.value!.cart_id;
                    this.cart = res.data.value as CartState["cart"];

                    ym(32889445,'reachGoal','add-to-cart');

                    if (showToast) {
                        if (useRoute().name !== "cart") {
                            useToast().success(
                                hasProductBefore
                                    ? "Количество изменено"
                                    : "Товар добавлен в корзину",
                            );
                        }
                    }
                } else if (showToast && useRoute().name !== "cart") {
                    useToast().error(
                        res.error.value?.data?.message ??
                            "Не удалось добавить товар в корзину",
                    );
                }
                return res;
            },
            async removeProduct(productId, quantity, showToast = true) {
                const res = await useFetchWithCredentials<{ cart_id: string }>(
                    "/api/v1/cart/delete",
                    {
                        method: "POST",
                        body: {
                            cart_id: this.cartId,
                            product_id: productId,
                            quantity,
                        },
                    },
                );
                if (res.status.value === "success") {
                    this.cartId = res.data.value!.cart_id;
                    this.cart = res.data.value as CartState["cart"];

                    if (showToast) {
                        if (useRoute().name !== "cart") {
                            useToast().success("Количество изменено");
                        }
                    }
                } else if (showToast && useRoute().name !== "cart") {
                    useToast().error(
                        res.error.value?.data?.message ??
                            "Не удалось убрать товар из корзины",
                    );
                }
                return res;
            },
            async applyPromocode(code: string) {
                const res = await useFetchWithCredentials<{ cart_id: string }>(
                    "/api/v1/cart/coupon",
                    {
                        method: "POST",
                        body: {
                            cart_id: this.cartId,
                            code,
                        },
                    },
                );

                if (res.status.value === "success") {
                    this.cartId = res.data.value!.cart_id;
                    this.cart = res.data.value as CartState["cart"];

                    useToast().success("Промокод успешно применен");
                }

                return res;
            },
            async removePromocode() {
                const res = await useFetchWithCredentials<{ cart_id: string }>(
                    "/api/v1/cart/coupon",
                    {
                        method: "DELETE",
                        body: {
                            cart_id: this.cartId,
                        },
                    },
                );

                if (res.status.value === "success") {
                    this.cartId = res.data.value!.cart_id;
                    this.cart = res.data.value as CartState["cart"];

                    useToast().success("Промокод успешно отменен");
                }

                return res;
            },
            hasProduct(id: number) {
                return !!this.cart?.positions?.find(
                    (product) => product.id == id,
                );
            },
            async buySolution(solutionId: number) {
                const res = useServerValidation(
                    await useFetchWithCredentials(
                        `/api/v1/solution/${solutionId}/cart`,
                        {
                            method: "post",
                            body: {
                                cart_id: this.cartId,
                            },
                        },
                    ),
                );

                if (res.status.value === "success") {
                    this.cartId = res.data.value!.cart_id;
                    this.cart = res.data.value as CartState["cart"];

                    useToast().success("Товар добавлен в корзину");

                    this.cartId = res.data?.value?.cart_id;
                    this.cart = res.data?.value;
                }

                return res;
            },
        },
        persist: {
            paths: ["cartId"],
        },
    },
);
