export async function useBreadcrumbs(crumbs: Array<object>) {
    const { t } = useI18n();

    return new Promise(async (resolve, reject) => {
        const crumbsState = useState("crumbs");

        crumbs.forEach((c, i) => {
            if (c?.name?.startsWith("i18n.")) {
                crumbs[i].name = t(c.name.split("i18n.").pop());
            }
        });

        crumbsState.value = [{ page: "index", name: t("breadcrumbs.index") }].concat(crumbs);

        if (process.server) {
            await useNuxtApp().callHook("app:pg");
        }

        resolve(true);
    });
}
