export function useFetchCart() {
    if (process.client) {
        const user = useAuth();
        watch(
            () => JSON.stringify(user.data),
            (data) => {
                if (data) {
                    useCart().load();
                }
            },
        );
    }
}
