import { default as index5Q3QHBKXsYMeta } from "/var/www/app/public/resources/js/client/pages/[slug]/index.vue?macro=true";
import { default as _91slug_93qjipvFQGslMeta } from "/var/www/app/public/resources/js/client/pages/articles/[slug].vue?macro=true";
import { default as indexnMyJmkzHhaMeta } from "/var/www/app/public/resources/js/client/pages/articles/index.vue?macro=true";
import { default as indexPdizGNQjTUMeta } from "/var/www/app/public/resources/js/client/pages/ateliers_works/[slug]/index.vue?macro=true";
import { default as indexAi3nMT1h6QMeta } from "/var/www/app/public/resources/js/client/pages/ateliers_works/index.vue?macro=true";
import { default as index9uLmj4eXDTMeta } from "/var/www/app/public/resources/js/client/pages/ateliers/[slug]/index.vue?macro=true";
import { default as indexHjtCHOMKNAMeta } from "/var/www/app/public/resources/js/client/pages/ateliers/index.vue?macro=true";
import { default as indexreRb9GQPVoMeta } from "/var/www/app/public/resources/js/client/pages/checkout/index.vue?macro=true";
import { default as orderQEz5hqU0hyMeta } from "/var/www/app/public/resources/js/client/pages/checkout/order.vue?macro=true";
import { default as _91slug_934i9zzeXzUDMeta } from "/var/www/app/public/resources/js/client/pages/checkout/success/[slug].vue?macro=true";
import { default as indexSmZOtW4dy9Meta } from "/var/www/app/public/resources/js/client/pages/contacts/[slug]/index.vue?macro=true";
import { default as productsYgS9HObGqOMeta } from "/var/www/app/public/resources/js/client/pages/contacts/[slug]/products.vue?macro=true";
import { default as indexK0WCXdH84AMeta } from "/var/www/app/public/resources/js/client/pages/contacts/index.vue?macro=true";
import { default as index6Tx4AolEXuMeta } from "/var/www/app/public/resources/js/client/pages/index.vue?macro=true";
import { default as login_45secreteasiGRDax0Meta } from "/var/www/app/public/resources/js/client/pages/login-secret.vue?macro=true";
import { default as maintenanceKlDdUO3RaqMeta } from "/var/www/app/public/resources/js/client/pages/maintenance.vue?macro=true";
import { default as _91slug_930yrVRbIAAgMeta } from "/var/www/app/public/resources/js/client/pages/manufacturers/[slug].vue?macro=true";
import { default as indexw1CwT5Yr3PMeta } from "/var/www/app/public/resources/js/client/pages/manufacturers/index.vue?macro=true";
import { default as _91slug_93J2Wp07VgVdMeta } from "/var/www/app/public/resources/js/client/pages/news/[slug].vue?macro=true";
import { default as index67av86m9oZMeta } from "/var/www/app/public/resources/js/client/pages/news/index.vue?macro=true";
import { default as _91slug_93y8boAY9jrgMeta } from "/var/www/app/public/resources/js/client/pages/p/[slug].vue?macro=true";
import { default as _91slug_93FBPM3ViWaqMeta } from "/var/www/app/public/resources/js/client/pages/page/[slug].vue?macro=true";
import { default as faq0hFQ63A0HlMeta } from "/var/www/app/public/resources/js/client/pages/page/faq.vue?macro=true";
import { default as indexMcnKriy7vxMeta } from "/var/www/app/public/resources/js/client/pages/payment/error/index.vue?macro=true";
import { default as index8dZJAVa2wEMeta } from "/var/www/app/public/resources/js/client/pages/payment/order/[hash]/index.vue?macro=true";
import { default as indexFVZRE5mwLaMeta } from "/var/www/app/public/resources/js/client/pages/payment/success/index.vue?macro=true";
import { default as reviewsvc1b5qTrxcMeta } from "/var/www/app/public/resources/js/client/pages/reviews.vue?macro=true";
import { default as searchKpK6zbKMi4Meta } from "/var/www/app/public/resources/js/client/pages/search.vue?macro=true";
import { default as sitemapH9bVtDGKQQMeta } from "/var/www/app/public/resources/js/client/pages/sitemap.vue?macro=true";
import { default as solutionsp88sm9GSu4Meta } from "/var/www/app/public/resources/js/client/pages/solutions.vue?macro=true";
import { default as _91slug_93QNgtNioX9fMeta } from "/var/www/app/public/resources/js/client/pages/special-offers/[slug].vue?macro=true";
import { default as indexVuTRKWHrXTMeta } from "/var/www/app/public/resources/js/client/pages/special-offers/index.vue?macro=true";
import { default as testt3hgv7X6fhoMeta } from "/var/www/app/public/resources/js/client/pages/testt.vue?macro=true";
import { default as favorite92QyZCh368Meta } from "/var/www/app/public/resources/js/client/pages/user/favorite.vue?macro=true";
import { default as indexMimGWPPiTJMeta } from "/var/www/app/public/resources/js/client/pages/user/index.vue?macro=true";
import { default as ordersNGpn1CLQaNMeta } from "/var/www/app/public/resources/js/client/pages/user/orders.vue?macro=true";
import { default as supportbUZcQRMjLGMeta } from "/var/www/app/public/resources/js/client/pages/user/support.vue?macro=true";
export default [
  {
    name: index5Q3QHBKXsYMeta?.name ?? "slug",
    path: index5Q3QHBKXsYMeta?.path ?? "/:slug()",
    meta: index5Q3QHBKXsYMeta || {},
    alias: index5Q3QHBKXsYMeta?.alias || [],
    redirect: index5Q3QHBKXsYMeta?.redirect || undefined,
    component: () => import("/var/www/app/public/resources/js/client/pages/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qjipvFQGslMeta?.name ?? "articles-slug",
    path: _91slug_93qjipvFQGslMeta?.path ?? "/articles/:slug()",
    meta: _91slug_93qjipvFQGslMeta || {},
    alias: _91slug_93qjipvFQGslMeta?.alias || [],
    redirect: _91slug_93qjipvFQGslMeta?.redirect || undefined,
    component: () => import("/var/www/app/public/resources/js/client/pages/articles/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexnMyJmkzHhaMeta?.name ?? "articles",
    path: indexnMyJmkzHhaMeta?.path ?? "/articles",
    meta: indexnMyJmkzHhaMeta || {},
    alias: indexnMyJmkzHhaMeta?.alias || [],
    redirect: indexnMyJmkzHhaMeta?.redirect || undefined,
    component: () => import("/var/www/app/public/resources/js/client/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: indexPdizGNQjTUMeta?.name ?? "ateliers_works-slug",
    path: indexPdizGNQjTUMeta?.path ?? "/ateliers_works/:slug()",
    meta: indexPdizGNQjTUMeta || {},
    alias: indexPdizGNQjTUMeta?.alias || [],
    redirect: indexPdizGNQjTUMeta?.redirect || undefined,
    component: () => import("/var/www/app/public/resources/js/client/pages/ateliers_works/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexAi3nMT1h6QMeta?.name ?? "ateliers_works",
    path: indexAi3nMT1h6QMeta?.path ?? "/ateliers_works",
    meta: indexAi3nMT1h6QMeta || {},
    alias: indexAi3nMT1h6QMeta?.alias || [],
    redirect: indexAi3nMT1h6QMeta?.redirect || undefined,
    component: () => import("/var/www/app/public/resources/js/client/pages/ateliers_works/index.vue").then(m => m.default || m)
  },
  {
    name: index9uLmj4eXDTMeta?.name ?? "ateliers-slug",
    path: index9uLmj4eXDTMeta?.path ?? "/ateliers/:slug()",
    meta: index9uLmj4eXDTMeta || {},
    alias: index9uLmj4eXDTMeta?.alias || [],
    redirect: index9uLmj4eXDTMeta?.redirect || undefined,
    component: () => import("/var/www/app/public/resources/js/client/pages/ateliers/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexHjtCHOMKNAMeta?.name ?? "ateliers",
    path: indexHjtCHOMKNAMeta?.path ?? "/ateliers",
    meta: indexHjtCHOMKNAMeta || {},
    alias: indexHjtCHOMKNAMeta?.alias || [],
    redirect: indexHjtCHOMKNAMeta?.redirect || undefined,
    component: () => import("/var/www/app/public/resources/js/client/pages/ateliers/index.vue").then(m => m.default || m)
  },
  {
    name: indexreRb9GQPVoMeta?.name ?? "checkout",
    path: indexreRb9GQPVoMeta?.path ?? "/checkout",
    meta: indexreRb9GQPVoMeta || {},
    alias: indexreRb9GQPVoMeta?.alias || [],
    redirect: indexreRb9GQPVoMeta?.redirect || undefined,
    component: () => import("/var/www/app/public/resources/js/client/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: orderQEz5hqU0hyMeta?.name ?? "checkout-order",
    path: orderQEz5hqU0hyMeta?.path ?? "/checkout/order",
    meta: orderQEz5hqU0hyMeta || {},
    alias: orderQEz5hqU0hyMeta?.alias || [],
    redirect: orderQEz5hqU0hyMeta?.redirect || undefined,
    component: () => import("/var/www/app/public/resources/js/client/pages/checkout/order.vue").then(m => m.default || m)
  },
  {
    name: _91slug_934i9zzeXzUDMeta?.name ?? "checkout-success-slug",
    path: _91slug_934i9zzeXzUDMeta?.path ?? "/checkout/success/:slug()",
    meta: _91slug_934i9zzeXzUDMeta || {},
    alias: _91slug_934i9zzeXzUDMeta?.alias || [],
    redirect: _91slug_934i9zzeXzUDMeta?.redirect || undefined,
    component: () => import("/var/www/app/public/resources/js/client/pages/checkout/success/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexSmZOtW4dy9Meta?.name ?? "contacts-slug",
    path: indexSmZOtW4dy9Meta?.path ?? "/contacts/:slug()",
    meta: indexSmZOtW4dy9Meta || {},
    alias: indexSmZOtW4dy9Meta?.alias || [],
    redirect: indexSmZOtW4dy9Meta?.redirect || undefined,
    component: () => import("/var/www/app/public/resources/js/client/pages/contacts/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: productsYgS9HObGqOMeta?.name ?? "contacts-slug-products",
    path: productsYgS9HObGqOMeta?.path ?? "/contacts/:slug()/products",
    meta: productsYgS9HObGqOMeta || {},
    alias: productsYgS9HObGqOMeta?.alias || [],
    redirect: productsYgS9HObGqOMeta?.redirect || undefined,
    component: () => import("/var/www/app/public/resources/js/client/pages/contacts/[slug]/products.vue").then(m => m.default || m)
  },
  {
    name: indexK0WCXdH84AMeta?.name ?? "contacts",
    path: indexK0WCXdH84AMeta?.path ?? "/contacts",
    meta: indexK0WCXdH84AMeta || {},
    alias: indexK0WCXdH84AMeta?.alias || [],
    redirect: indexK0WCXdH84AMeta?.redirect || undefined,
    component: () => import("/var/www/app/public/resources/js/client/pages/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: index6Tx4AolEXuMeta?.name ?? "index",
    path: index6Tx4AolEXuMeta?.path ?? "/",
    meta: index6Tx4AolEXuMeta || {},
    alias: index6Tx4AolEXuMeta?.alias || [],
    redirect: index6Tx4AolEXuMeta?.redirect || undefined,
    component: () => import("/var/www/app/public/resources/js/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: login_45secreteasiGRDax0Meta?.name ?? "login-secret",
    path: login_45secreteasiGRDax0Meta?.path ?? "/login-secret",
    meta: login_45secreteasiGRDax0Meta || {},
    alias: login_45secreteasiGRDax0Meta?.alias || [],
    redirect: login_45secreteasiGRDax0Meta?.redirect || undefined,
    component: () => import("/var/www/app/public/resources/js/client/pages/login-secret.vue").then(m => m.default || m)
  },
  {
    name: maintenanceKlDdUO3RaqMeta?.name ?? "maintenance",
    path: maintenanceKlDdUO3RaqMeta?.path ?? "/maintenance",
    meta: maintenanceKlDdUO3RaqMeta || {},
    alias: maintenanceKlDdUO3RaqMeta?.alias || [],
    redirect: maintenanceKlDdUO3RaqMeta?.redirect || undefined,
    component: () => import("/var/www/app/public/resources/js/client/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: _91slug_930yrVRbIAAgMeta?.name ?? "manufacturers-slug",
    path: _91slug_930yrVRbIAAgMeta?.path ?? "/manufacturers/:slug()",
    meta: _91slug_930yrVRbIAAgMeta || {},
    alias: _91slug_930yrVRbIAAgMeta?.alias || [],
    redirect: _91slug_930yrVRbIAAgMeta?.redirect || undefined,
    component: () => import("/var/www/app/public/resources/js/client/pages/manufacturers/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexw1CwT5Yr3PMeta?.name ?? "manufacturers",
    path: indexw1CwT5Yr3PMeta?.path ?? "/manufacturers",
    meta: indexw1CwT5Yr3PMeta || {},
    alias: indexw1CwT5Yr3PMeta?.alias || [],
    redirect: indexw1CwT5Yr3PMeta?.redirect || undefined,
    component: () => import("/var/www/app/public/resources/js/client/pages/manufacturers/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93J2Wp07VgVdMeta?.name ?? "news-slug",
    path: _91slug_93J2Wp07VgVdMeta?.path ?? "/news/:slug()",
    meta: _91slug_93J2Wp07VgVdMeta || {},
    alias: _91slug_93J2Wp07VgVdMeta?.alias || [],
    redirect: _91slug_93J2Wp07VgVdMeta?.redirect || undefined,
    component: () => import("/var/www/app/public/resources/js/client/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: index67av86m9oZMeta?.name ?? "news",
    path: index67av86m9oZMeta?.path ?? "/news",
    meta: index67av86m9oZMeta || {},
    alias: index67av86m9oZMeta?.alias || [],
    redirect: index67av86m9oZMeta?.redirect || undefined,
    component: () => import("/var/www/app/public/resources/js/client/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93y8boAY9jrgMeta?.name ?? "p-slug",
    path: _91slug_93y8boAY9jrgMeta?.path ?? "/p/:slug()",
    meta: _91slug_93y8boAY9jrgMeta || {},
    alias: _91slug_93y8boAY9jrgMeta?.alias || [],
    redirect: _91slug_93y8boAY9jrgMeta?.redirect || undefined,
    component: () => import("/var/www/app/public/resources/js/client/pages/p/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FBPM3ViWaqMeta?.name ?? "page-slug",
    path: _91slug_93FBPM3ViWaqMeta?.path ?? "/page/:slug()",
    meta: _91slug_93FBPM3ViWaqMeta || {},
    alias: _91slug_93FBPM3ViWaqMeta?.alias || [],
    redirect: _91slug_93FBPM3ViWaqMeta?.redirect || undefined,
    component: () => import("/var/www/app/public/resources/js/client/pages/page/[slug].vue").then(m => m.default || m)
  },
  {
    name: faq0hFQ63A0HlMeta?.name ?? "page-faq",
    path: faq0hFQ63A0HlMeta?.path ?? "/page/faq",
    meta: faq0hFQ63A0HlMeta || {},
    alias: faq0hFQ63A0HlMeta?.alias || [],
    redirect: faq0hFQ63A0HlMeta?.redirect || undefined,
    component: () => import("/var/www/app/public/resources/js/client/pages/page/faq.vue").then(m => m.default || m)
  },
  {
    name: indexMcnKriy7vxMeta?.name ?? "payment-error",
    path: indexMcnKriy7vxMeta?.path ?? "/payment/error",
    meta: indexMcnKriy7vxMeta || {},
    alias: indexMcnKriy7vxMeta?.alias || [],
    redirect: indexMcnKriy7vxMeta?.redirect || undefined,
    component: () => import("/var/www/app/public/resources/js/client/pages/payment/error/index.vue").then(m => m.default || m)
  },
  {
    name: index8dZJAVa2wEMeta?.name ?? "payment-order-hash",
    path: index8dZJAVa2wEMeta?.path ?? "/payment/order/:hash()",
    meta: index8dZJAVa2wEMeta || {},
    alias: index8dZJAVa2wEMeta?.alias || [],
    redirect: index8dZJAVa2wEMeta?.redirect || undefined,
    component: () => import("/var/www/app/public/resources/js/client/pages/payment/order/[hash]/index.vue").then(m => m.default || m)
  },
  {
    name: indexFVZRE5mwLaMeta?.name ?? "payment-success",
    path: indexFVZRE5mwLaMeta?.path ?? "/payment/success",
    meta: indexFVZRE5mwLaMeta || {},
    alias: indexFVZRE5mwLaMeta?.alias || [],
    redirect: indexFVZRE5mwLaMeta?.redirect || undefined,
    component: () => import("/var/www/app/public/resources/js/client/pages/payment/success/index.vue").then(m => m.default || m)
  },
  {
    name: reviewsvc1b5qTrxcMeta?.name ?? "reviews",
    path: reviewsvc1b5qTrxcMeta?.path ?? "/reviews",
    meta: reviewsvc1b5qTrxcMeta || {},
    alias: reviewsvc1b5qTrxcMeta?.alias || [],
    redirect: reviewsvc1b5qTrxcMeta?.redirect || undefined,
    component: () => import("/var/www/app/public/resources/js/client/pages/reviews.vue").then(m => m.default || m)
  },
  {
    name: searchKpK6zbKMi4Meta?.name ?? "search",
    path: searchKpK6zbKMi4Meta?.path ?? "/search",
    meta: searchKpK6zbKMi4Meta || {},
    alias: searchKpK6zbKMi4Meta?.alias || [],
    redirect: searchKpK6zbKMi4Meta?.redirect || undefined,
    component: () => import("/var/www/app/public/resources/js/client/pages/search.vue").then(m => m.default || m)
  },
  {
    name: sitemapH9bVtDGKQQMeta?.name ?? "sitemap",
    path: sitemapH9bVtDGKQQMeta?.path ?? "/sitemap",
    meta: sitemapH9bVtDGKQQMeta || {},
    alias: sitemapH9bVtDGKQQMeta?.alias || [],
    redirect: sitemapH9bVtDGKQQMeta?.redirect || undefined,
    component: () => import("/var/www/app/public/resources/js/client/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: solutionsp88sm9GSu4Meta?.name ?? "solutions",
    path: solutionsp88sm9GSu4Meta?.path ?? "/solutions",
    meta: solutionsp88sm9GSu4Meta || {},
    alias: solutionsp88sm9GSu4Meta?.alias || [],
    redirect: solutionsp88sm9GSu4Meta?.redirect || undefined,
    component: () => import("/var/www/app/public/resources/js/client/pages/solutions.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QNgtNioX9fMeta?.name ?? "special-offers-slug",
    path: _91slug_93QNgtNioX9fMeta?.path ?? "/special-offers/:slug()",
    meta: _91slug_93QNgtNioX9fMeta || {},
    alias: _91slug_93QNgtNioX9fMeta?.alias || [],
    redirect: _91slug_93QNgtNioX9fMeta?.redirect || undefined,
    component: () => import("/var/www/app/public/resources/js/client/pages/special-offers/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexVuTRKWHrXTMeta?.name ?? "special-offers",
    path: indexVuTRKWHrXTMeta?.path ?? "/special-offers",
    meta: indexVuTRKWHrXTMeta || {},
    alias: indexVuTRKWHrXTMeta?.alias || [],
    redirect: indexVuTRKWHrXTMeta?.redirect || undefined,
    component: () => import("/var/www/app/public/resources/js/client/pages/special-offers/index.vue").then(m => m.default || m)
  },
  {
    name: testt3hgv7X6fhoMeta?.name ?? "testt",
    path: testt3hgv7X6fhoMeta?.path ?? "/testt",
    meta: testt3hgv7X6fhoMeta || {},
    alias: testt3hgv7X6fhoMeta?.alias || [],
    redirect: testt3hgv7X6fhoMeta?.redirect || undefined,
    component: () => import("/var/www/app/public/resources/js/client/pages/testt.vue").then(m => m.default || m)
  },
  {
    name: favorite92QyZCh368Meta?.name ?? "user-favorite",
    path: favorite92QyZCh368Meta?.path ?? "/user/favorite",
    meta: favorite92QyZCh368Meta || {},
    alias: favorite92QyZCh368Meta?.alias || [],
    redirect: favorite92QyZCh368Meta?.redirect || undefined,
    component: () => import("/var/www/app/public/resources/js/client/pages/user/favorite.vue").then(m => m.default || m)
  },
  {
    name: indexMimGWPPiTJMeta?.name ?? "user",
    path: indexMimGWPPiTJMeta?.path ?? "/user",
    meta: indexMimGWPPiTJMeta || {},
    alias: indexMimGWPPiTJMeta?.alias || [],
    redirect: indexMimGWPPiTJMeta?.redirect || undefined,
    component: () => import("/var/www/app/public/resources/js/client/pages/user/index.vue").then(m => m.default || m)
  },
  {
    name: ordersNGpn1CLQaNMeta?.name ?? "user-orders",
    path: ordersNGpn1CLQaNMeta?.path ?? "/user/orders",
    meta: ordersNGpn1CLQaNMeta || {},
    alias: ordersNGpn1CLQaNMeta?.alias || [],
    redirect: ordersNGpn1CLQaNMeta?.redirect || undefined,
    component: () => import("/var/www/app/public/resources/js/client/pages/user/orders.vue").then(m => m.default || m)
  },
  {
    name: supportbUZcQRMjLGMeta?.name ?? "user-support",
    path: supportbUZcQRMjLGMeta?.path ?? "/user/support",
    meta: supportbUZcQRMjLGMeta || {},
    alias: supportbUZcQRMjLGMeta?.alias || [],
    redirect: supportbUZcQRMjLGMeta?.redirect || undefined,
    component: () => import("/var/www/app/public/resources/js/client/pages/user/support.vue").then(m => m.default || m)
  }
]