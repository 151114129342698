import revive_payload_client_Frd8gASYWa from "/var/www/app/public/resources/js/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_3Bi0E2Ktsf from "/var/www/app/public/resources/js/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_CrWB4n4PyO from "/var/www/app/public/resources/js/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_DgG5Jh65GV from "/var/www/app/public/resources/js/node_modules/nuxt-site-config/dist/runtime/plugins/0.siteConfig.mjs";
import payload_client_T5aD6w6QZh from "/var/www/app/public/resources/js/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_aQoh9E5uaZ from "/var/www/app/public/resources/js/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/var/www/app/public/resources/js/client/.nuxt/components.plugin.mjs";
import prefetch_client_5ZRYOjuei0 from "/var/www/app/public/resources/js/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_049HX4F1aJ from "/var/www/app/public/resources/js/node_modules/nuxt-site-config/dist/runtime/plugins/i18n.mjs";
import primevue_plugin_egKpok8Auk from "/var/www/app/public/resources/js/client/.nuxt/primevue-plugin.mjs";
import plugin_client_1Uezc41sij from "/var/www/app/public/resources/js/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import plugin_L4dx7UUNO5 from "/var/www/app/public/resources/js/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import i18n_huatSqtcYP from "/var/www/app/public/resources/js/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import sentry_client_UWUxmn85Fk from "/var/www/app/public/resources/js/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/var/www/app/public/resources/js/client/.nuxt/sentry-client-config.mjs";
import chunk_reload_client_kCUITUWXUN from "/var/www/app/public/resources/js/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_yxgjJ9XxnV from "/var/www/app/public/resources/js/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_cKrA5WGj38 from "/var/www/app/public/resources/js/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import api_GFfDXud5Cr from "/var/www/app/public/resources/js/client/plugins/api.ts";
import clickOutside_ZB3Eg0wOVk from "/var/www/app/public/resources/js/client/plugins/clickOutside.ts";
import maska_UHaKf2z1iQ from "/var/www/app/public/resources/js/client/plugins/maska.ts";
import toast_ysMjUcU7eJ from "/var/www/app/public/resources/js/client/plugins/toast.ts";
import yandexMap_M2Kz4NfiIu from "/var/www/app/public/resources/js/client/plugins/yandexMap.ts";
import yupI18n_roY6Y60ypa from "/var/www/app/public/resources/js/client/plugins/yupI18n.ts";
export default [
  revive_payload_client_Frd8gASYWa,
  unhead_3Bi0E2Ktsf,
  router_CrWB4n4PyO,
  _0_siteConfig_DgG5Jh65GV,
  payload_client_T5aD6w6QZh,
  plugin_vue3_aQoh9E5uaZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5ZRYOjuei0,
  i18n_049HX4F1aJ,
  primevue_plugin_egKpok8Auk,
  plugin_client_1Uezc41sij,
  plugin_L4dx7UUNO5,
  i18n_huatSqtcYP,
  sentry_client_UWUxmn85Fk,
  sentry_client_config_o34nk2sJbg,
  chunk_reload_client_kCUITUWXUN,
  check_outdated_build_client_yxgjJ9XxnV,
  plugin_cKrA5WGj38,
  api_GFfDXud5Cr,
  clickOutside_ZB3Eg0wOVk,
  maska_UHaKf2z1iQ,
  toast_ysMjUcU7eJ,
  yandexMap_M2Kz4NfiIu,
  yupI18n_roY6Y60ypa
]