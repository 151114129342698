import validate from "/var/www/app/public/resources/js/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global_45global from "/var/www/app/public/resources/js/client/middleware/auth-global.global.ts";
import load_45cart_45global from "/var/www/app/public/resources/js/client/middleware/loadCart.global.ts";
import redirect_45from_45signin_45global from "/var/www/app/public/resources/js/client/middleware/redirect-from-signin.global.ts";
import telescope_45global from "/var/www/app/public/resources/js/client/middleware/telescope.global.ts";
import trailing_45slash_45global from "/var/www/app/public/resources/js/client/middleware/trailing-slash.global.ts";
import manifest_45route_45rule from "/var/www/app/public/resources/js/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global_45global,
  load_45cart_45global,
  redirect_45from_45signin_45global,
  telescope_45global,
  trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/var/www/app/public/resources/js/client/middleware/auth.ts")
}