import type { UseFetchOptions } from 'nuxt/app'

export function useFetchWithCredentials<T>(
    url: string | (() => string),
    options: Omit<UseFetchOptions<T>, 'default'> & { default: () => T | Ref<T> },
) {
    const key = options?.key ?? (keyGen())!;


    return useFetch(url, {
        ...options,
        key,
        $fetch: useNuxtApp().$api
    })
}

function keyGen() {
    return process.server ? null : Math.random().toString();
}
