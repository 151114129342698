<script setup>
import { storeToRefs } from "pinia";
import { useModalStore } from "@/stores/modal";

const modal = useModalStore();
const model = reactive({});

const { isOpen, view, args, className } = storeToRefs(modal);

onMounted(() => {
    window.addEventListener("keydown", (e) => {
        if (e.key === "Escape") {
            modal.close();
        }
    });
});

function event(data) {
    modal.args.data = data;
}
</script>

<template>
    <div v-if="isOpen">
        <div class="modal-overlay" @click="modal.close()"></div>

        <div class="modal" :class="className">
            <div class="modal__wrap">
                <button class="modal-close" @click="modal.close()"></button>

                <component
                    :is="view"
                    v-bind="args"
                    v-model="model"
                    @event="event"
                ></component>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
body:has(.modal) {
    overflow: hidden;
}

.modal-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    z-index: 999;
    transition: all 0.5s;
}

.modal {
    width: 100%;
    min-width: 320px;
    max-width: 824px;
    position: fixed;
    z-index: 1010;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 100;
    transition: all 0.5s;
    overflow-y: auto;
    max-height: 95vh;
    background: #ffffff;

    @media (max-width: 991px) {
        width: 95%;
    }

    &__wrap {
        padding: 50px 175px;

        @media (max-width: 991px) {
            padding: 50px 84px;
        }

        @media (max-width: 575px) {
            padding: 52px 24px;
        }

        .modal-close {
            border: 0;
            background: none;
            display: block;
            width: 17px;
            height: 17px;
            position: absolute;
            top: 31px;
            right: 30px;
            z-index: 100;

            &:before,
            &:after {
                content: "";
                display: block;
                height: 1px;
                border-radius: 15px;
                background: #000000;
                width: 17px;
                position: absolute;
                left: 0;
                transition: background 0.3s;
            }

            &:before {
                transform: rotate(45deg);
            }

            &:after {
                transform: rotate(-45deg);
            }
        }
    }
}
</style>
